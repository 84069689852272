@import 'nib'


#sms_resend_modal
	padding-right 0 !important

	&.modal
		
		.modal-dialog
			padding 45px 15px 20px
			margin 0 auto
			width 830px
			max-width 100%
		
		.modal-content
			box-shadow 0 5px 5px rgba(3,3,3,.2)
			border 0
			
		.modal-header
			position relative
			text-align center
			background #1177a5
			color #fff
			border 0
			border-radius 4px 4px 0 0
			padding 24px 15px
			
			h4 
				font-size 24px
				font-weight 700
				margin 0 0 20px
				line-height 1.1
				
			.close
				width 24px
				height 24px
				line-height 24px
				text-align center
				padding 0
				opacity 1
				background #fff
				box-shadow none
				position absolute
				top -8px 
				right -11px
				border-radius 500px

				img 
					width 11px
					height 11px
					margin 0
					top 0
					
		.modal-body
			padding 24px
			font-size 16px
			color #333333
			line-height 1.4
	
	.modal-header
		height 114px
		margin 0 0 59px
		
	.sms-modal-icon
		display block
		margin 0 auto
		width 108px
		height 108px
		line-height 108px
		background #fff
		border-radius 500px
		box-shadow 0 5px 5px rgba(0,0,0,.09)
		text-align center
		
		img
			position relative
			top -3px
			
	.modal-body
		text-align center
		padding 24px 130px
		

	p 
		margin 0 0 24px
			
	
	.sms-verification-fields
		width 490px
		max-width 100%
		margin 0 auto 40px

		&:before
		&:after
			content ''
			display table
			width 100%
			clear both	
			
		a 
			display block
			width 200px
			float right
			height 46px
			line-height 46px
			position relative
			top 0
			box-shadow 0 4px 0 #e18433
			background #fa9842
			color #fff
			border-radius 500px
			font-weight 700
			transition .5s ease-in-out
			
			
			img 
				position relative
				width 12px
				height 12px
				margin 0 0 0 10px
				
			&:hover,
			&:focus
				text-decoration none
				
			&:hover 
				background lighten(#fa9842, 20%)
				
			&:focus
				height 50px
				line-height 50px
				background #e18433
				box-shadow 0 0 0 darken(#e18433, 10%)


	.input-flds-wrap
		width 270px
		float left
		max-width 100%
		
		div
			width 25%
			float left
			padding 0 10px
			
		&:before
		&:after
			content ''
			display table
			width 100%
			clear both	
			
		input:not([type='checkbox'])
			width 100%
			padding 8px 14px
			line-height normal
			font-size 26px
			color #212121
			border-radius 4px
			border 1px solid #b8b8b8
			text-align center
			appearance: none
			-moz-appearance: textfield
			box-shadow 0 0 0 rgba(250, 152, 66, .35)
			height 50px
			
			&::-webkit-inner-spin-button
			&::-webkit-outer-spin-button
				appearance: none
				margin 0
				
			&:focus
				box-shadow 0 0 8px rgba(250, 152, 66, .35)
				
			&.error 
				box-shadow 0 0 8px rgba(255, 0, 0, .35)
				border-color rgba(255, 0, 0, 1)
				

		&.got-icon
			padding 0 10px
			
			input:not([type='checkbox'])
				padding-left 58px
				font-size 16px
				text-align left
				background url(https://asset.alternativemedia.com.au/articleimagehosting/eng-sms-phone-icon.png) 19px 50% no-repeat
				
			
	.sms-error 
		clear both
		padding 10px 15px
		text-align center
		background rgba(255,0,0,1)
		color #fff
		margin 0 0 10px 10px
		font-size 14px
		display block
		border-radius 4px
			
		&:before
		&:after
			content ''
			display table
			width 100%
			clear both
			
		&.sms-success
			background #7bcb54
		

	@media (max-width 767px)
		.modal-header
			height 90px
			margin-bottom 40px
			
			h4 
				margin-bottom 10px
			
		.sms-modal-icon
			width 70px
			height 70px
			line-height 70px
			
			img 
				width 30px
				height auto
				
		.modal-body
			padding 20px 
			

		.input-flds-wrap
			margin 0 auto 10px
			float none
			
			div
				padding 0 5px
				
		.sms-verification-fields 
			a
				float none
				margin 0 auto
				clear both
				
		.sms-error
			max-width 260px
			margin 0 auto 10px
			
			
		
		


		

			

